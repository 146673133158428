<div class="mb-4">
  <div class="text-base text-heading-light font-medium mr-2">
    ATS Layout
  </div>
  <div class="ats-layout-container rounded-2xl mt-2 w-full">
    @for (layoutSelectionModel of atsLayoutSelectionModels; track layoutSelectionModel?.atsLayoutType) {
      <div class="flex justify-center items-center m-0.5 p-2.5 cursor-pointer"
           [ngClass]="{'inactive-button-layout-button':layoutSelectionModel?.atsLayoutType != currentLayoutType,'active-button-layout-button':layoutSelectionModel?.atsLayoutType == currentLayoutType}"
           (click)="onClick(layoutSelectionModel)">
        <div class="text-xs text-heading font-normal"
             [ngClass]="{'active-section':layoutSelectionModel?.atsLayoutType == currentLayoutType}">
          {{ ATS_LAYOUT_TYPE_DISPLAY[layoutSelectionModel?.atsLayoutType] }}
        </div>
      </div>
    }
  </div>
</div>
