<div class="flex items-center justify-center py-4 px-5 h-14 bg-cover bg-center cursor-pointer footer-card gap-2"
     (click)="onClickFooter()">
    <img src="assets/images/white-star.svg">
    <div class="text-white text-sm">Share with your friends.</div>
    <div class="rotate-270">
        @if (isBrowser) {
            <ng-lottie [options]="lottieAnimationOptions"
                       height="30px" width="30px">
            </ng-lottie>
        }
    </div>
</div>

