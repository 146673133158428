import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    ATS_LAYOUT_TYPE,
    AtsLayoutSelectionModel,
    LAYOUT_TYPE,
    LayoutSelectionModel
} from "../../../resume/common/layout-selection.model";
import {AtsLayoutSelectionComponent} from "../../common/component/ats-layout-selection/ats-layout-selection.component";
import {
    ResumeLayoutSelectionComponent
} from "../../common/component/resume-layout-selection/resume-layout-selection.component";
import {
    ResumeThemeIndicatorComponent,
    ResumeThemeSelectionModel
} from "../../common/component/resume-theme-indicator/resume-theme-indicator.component";
import {RESUME_THEME_TYPE, ResumeOutDto} from "../../../resume/common/resume.model";
import {Subscription} from "rxjs";
import {ResumeStore} from "../../common/store/resume.store";
import {LibCircleScoreBarComponent} from "../../../../library/circle-score-bar/lib-circle-score-bar.component";
import {PublicApiResumeService, ResumePatchInDto} from "../../../resume/service/public-api-resume.service";

@Component({
    selector: 'tal-left-action-panel',
    standalone: true,
    imports: [
        AtsLayoutSelectionComponent,
        ResumeLayoutSelectionComponent,
        ResumeThemeIndicatorComponent,
        LibCircleScoreBarComponent
    ],
    templateUrl: './left-action-panel.component.html',
    styleUrl: './left-action-panel.component.scss'
})
export class LeftActionPanelComponent implements OnInit, OnDestroy {

    resumeOutDto: ResumeOutDto;
    layoutSelectionModels: LayoutSelectionModel[];
    atsLayoutSelectionModels: AtsLayoutSelectionModel[];
    selectedResumeThemeType: RESUME_THEME_TYPE;

    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;
    private subscription = new Subscription();


    constructor(private resumeStore: ResumeStore,
                private publicApiResumeService: PublicApiResumeService) {
        this.setLayoutModel();
        this.setAtsLayoutModel();
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    setLayoutSelectionType(layoutSelectionModel: LayoutSelectionModel): void {
        this.resumeOutDto.layoutType = layoutSelectionModel?.layoutType;
        this.resumeStore.publishAndGetUpdatedResume(this.resumeOutDto);
        this.patchResume();
    }

    setAtsSelectionType(atsLayoutSelectionModel: AtsLayoutSelectionModel): void {
        this.resumeOutDto.layoutType = LAYOUT_TYPE.ONE_COLUMN;
        this.resumeOutDto.atsLayout = atsLayoutSelectionModel?.atsLayoutType;
        this.resumeStore.publishAndGetUpdatedResume(this.resumeOutDto);
        this.patchResume();
    }

    updateResumeTheme(resumeThemeSelectionModel: ResumeThemeSelectionModel): void {
        this.selectedResumeThemeType = resumeThemeSelectionModel?.themeType;
        this.resumeOutDto.theme = this.selectedResumeThemeType;
        this.resumeStore.publishAndGetUpdatedResume(this.resumeOutDto);
        this.patchResume();
    }

    private patchResume() {
        this.publicApiResumeService.patch(this.resumeOutDto?.id, this.getResumePatchInDto())
            .subscribe(resumeOutDto => {
                this.resumeOutDto = resumeOutDto;
            });
    }

    private setLayoutModel(): void {
        this.layoutSelectionModels = [
            {
                layoutType: LAYOUT_TYPE.TWO_COLUMN,
                imageUrl: 'ti ti-layout-sidebar',
            },
            {
                layoutType: LAYOUT_TYPE.ONE_COLUMN,
                imageUrl: 'ti ti-layout-navbar',
            }
        ] as LayoutSelectionModel[];
    };

    private setAtsLayoutModel(): void {
        this.atsLayoutSelectionModels = [
            {
                atsLayoutType: ATS_LAYOUT_TYPE.MODERN,
            },
            {
                atsLayoutType: ATS_LAYOUT_TYPE.CLASSIC,
            }
        ] as AtsLayoutSelectionModel[];
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto.subscribe(resumeOutDto => {
            if (resumeOutDto) {
                this.resumeOutDto = resumeOutDto;
                this.selectedResumeThemeType = resumeOutDto?.theme;
            }
        }));
    }

    private getResumePatchInDto(): ResumePatchInDto {
        return {
            name: this.resumeOutDto?.name,
            title: this.resumeOutDto?.title,
            isDefault: this.resumeOutDto?.isDefault,
            template: this.resumeOutDto?.template,
            theme: this.resumeOutDto?.theme,
            atsLayout: this.resumeOutDto?.atsLayout,
            urlHandle: this.resumeOutDto?.urlHandle,
            removeBranding: this.resumeOutDto?.removeBranding,
            layoutType: this.resumeOutDto?.layoutType
        } as ResumePatchInDto;
    }
}
