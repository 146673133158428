import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LAYOUT_TYPE, LAYOUT_TYPE_DISPLAY, LayoutSelectionModel} from "../../../../resume/common/layout-selection.model";
import {NgClass} from "@angular/common";

@Component({
  selector: 'tal-resume-layout-selection',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './resume-layout-selection.component.html',
  styleUrl: './resume-layout-selection.component.scss'
})
export class ResumeLayoutSelectionComponent implements OnInit {
  @Input() layoutSelectionModels: LayoutSelectionModel[];
  @Input() currentLayoutType = LAYOUT_TYPE.ONE_COLUMN;
  @Output() layoutTypeEvent: EventEmitter<any> = new EventEmitter();
  protected readonly LAYOUT_TYPE_DISPLAY = LAYOUT_TYPE_DISPLAY;

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(layoutSelectionModel: LayoutSelectionModel): void {
    this.layoutTypeEvent.emit(layoutSelectionModel);
  }
}
