import {Component, OnInit} from '@angular/core';
import {ResumeOutDto} from "../../resume/common/resume.model";
import {PublicApiResumeService} from "../../resume/service/public-api-resume.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ROUTE} from "../../../common/route-constants/route";
import {ResumeStore} from "../common/store/resume.store";
import {LeftActionPanelComponent} from "./left-action-panel/left-action-panel.component";
import {RightActionPanelComponent} from "./right-action-panel/right-action-panel.component";
import {NgTemplateOutlet} from "@angular/common";
import {BUTTON_SUB_TYPE, LibButtonComponent} from "../../../library/button/lib-button/lib-button.component";
import {AiResumeFooterComponent} from "../common/component/ai-resume-footer/ai-resume-footer.component";
import {ResumeComponent} from "../../resume/resume.component";

@Component({
    selector: 'tal-ai-resume-builder',
    standalone: true,
    imports: [
        LeftActionPanelComponent,
        RightActionPanelComponent,
        NgTemplateOutlet,
        LibButtonComponent,
        AiResumeFooterComponent,
        ResumeComponent
    ],
    templateUrl: './ai-resume-builder.component.html',
    styleUrl: './ai-resume-builder.component.scss'
})
export class AiResumeBuilderComponent implements OnInit {
    resumeOutDto: ResumeOutDto;
    protected readonly BUTTON_SUB_TYPE = BUTTON_SUB_TYPE;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private resumeStore: ResumeStore,
                private resumeService: PublicApiResumeService) {
        this.setResumeOutDtoFromResumeId();
    }

    ngOnInit(): void {
    }

    navigateToUploadResume(): void {
        this.router.navigate([ROUTE.FIX_RESUME]);
    }

    private setResumeOutDtoFromResumeId(): void {
        let resumeId = this.activatedRoute?.snapshot?.params[ROUTE.RESUME_ID];
        this.resumeService.findPublicResume(resumeId)
            .subscribe(resumeOutDto => {
                    this.resumeOutDto = resumeOutDto;
                    this.resumeStore.publishAndGetUpdatedResume(this.resumeOutDto);
                }
            );
    }
}
