<div class="relative">
    @if (headerIcon || headerSubTitle || headerTitle || !hideCloseIcon) {
        <div class="flex flex-row justify-between items-center p-6 sticky top-0 z-10 header-background">
            <div class="flex flex-row justify-start items-center">
                <div class="text-3xl text-primary" [ngClass]="headerIcon"></div>
                <div class="flex flex-col justify-start items-start pl-1">
                    <div
                        class="font-normal text-sm text-slate-400 capitalize truncate title-width">{{ headerSubTitle }}
                    </div>
                    <div
                        class="font-medium text-lg text-heading-primary capitalize truncate title-width">{{ headerTitle }}
                    </div>
                </div>
            </div>
            @if (!hideCloseIcon) {
                <div class="ti ti-x text-2xl cursor-pointer text-heading-secondary" (click)="closeDialog()"></div>
            }
        </div>
    }
    <mat-dialog-content>
        <ng-content></ng-content>
    </mat-dialog-content>
    <div class="flex flex-row justify-between items-center">
        @if (imageUrl) {
            <div class="px-6 py-3">
                <img [src]=imageUrl alt="" width="100">
            </div>
        }
        @if (primaryButtonText || secondaryButtonText || dangerButtonText) {
            <div class="flex flex-row justify-end items-center place-content-end p-6 w-full"
                 [ngClass]="primaryButtonText || secondaryButtonText || dangerButtonText ? 'p-6' : 'p-2'">
                @if (secondaryButtonText) {
                    <lib-button [ngClass]="secondaryButtonType ? 'w-full mr-6' : 'mr-6'"
                                [libSubType]="BUTTON_SUB_TYPE.SECONDARY"
                                [iconClass]="secondaryButtonIcon"
                                [libType]="secondaryButtonType"
                                (buttonClick)="isSecondaryButtonClickedEvent ? secondaryButtonClicked() : closeDialog()">
                        {{ secondaryButtonText }}
                    </lib-button>
                }
                @if (primaryButtonText) {
                    <lib-button [ngClass]="primaryButtonType ? 'w-full' : ''"
                                [libSubType]="BUTTON_SUB_TYPE.PRIMARY"
                                [iconClass]="primaryButtonIcon"
                                [libType]="primaryButtonType"
                                [disabled]="primaryButtonDisabled"
                                (buttonClick)="primaryButtonClicked()">
                        {{ primaryButtonText }}
                    </lib-button>
                }
                @if (dangerButtonText) {
                    <lib-button [libSubType]="BUTTON_SUB_TYPE.DANGER"
                                [iconClass]="dangerButtonIcon"
                                (buttonClick)="dangerButtonClicked()">
                        {{ dangerButtonText }}
                    </lib-button>
                }
            </div>
        }
    </div>

    @if (customTemplateRef) {
        <ng-container [ngTemplateOutlet]="customTemplateRef"></ng-container>
    }
    @if (footerTemplateRef) {
        <div class="sticky bottom-0">
            <ng-container [ngTemplateOutlet]="footerTemplateRef"></ng-container>
        </div>
    }
</div>
