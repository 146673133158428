import {Injectable} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition
} from "@angular/material/snack-bar";
import {ComponentType} from "@angular/cdk/overlay";
import {LibSnackbarComponent} from "../../lib-snackbar.component";
import {LibSnackbarUtil} from "../util/lib-snackbar.util";

@Injectable({
  providedIn: 'root'
})
export class LibSnackbarService {
  constructor(private matSnackBarRef: MatSnackBar) {
  }

  openSnackBar(snackBarConfig: LibSnackBarConfig): void {
    this.matSnackBarRef.openFromComponent(LibSnackbarComponent, this.setSnackbarConfig(snackBarConfig));
  }

  public openFromComponent<T>(component: ComponentType<T>, config?: MatSnackBarConfig): MatSnackBarRef<T> {
    config = this.getDefaultConfig(config);
    return this.matSnackBarRef.openFromComponent(component, config);
  }

  private setMatSnackbarConfigData(message?: string, snackbarType?: string, imageUrl?: string): LibSnackBarConfig {
    return {
      message: message,
      snackBarRef: this.matSnackBarRef,
      type: snackbarType,
      imageUrl: imageUrl
    } as LibSnackBarConfig
  }

  private setSnackbarConfig(snackBarConfig: LibSnackBarConfig): MatSnackBarConfig {
    return {
      data: this.setMatSnackbarConfigData(snackBarConfig?.message, snackBarConfig?.type, snackBarConfig?.imageUrl),
      duration: snackBarConfig?.duration,
      horizontalPosition: snackBarConfig?.horizontalPosition,
      verticalPosition: snackBarConfig?.verticalPosition,
      panelClass: snackBarConfig?.panelClass
    } as MatSnackBarConfig;
  }

  private getDefaultConfig(config: MatSnackBarConfig<any>): MatSnackBarConfig<any> {
    return config ? this.updateConfig(config) : {
      panelClass: 'custom-snackbar'
    };
  }

  private updateConfig(config: MatSnackBarConfig<any>): MatSnackBarConfig<any> {
    config.panelClass = 'custom-snackbar' + (config?.panelClass ? ' ' + config?.panelClass : '');
    config.horizontalPosition = config?.horizontalPosition || 'right';
    config.verticalPosition = config?.verticalPosition || 'bottom';
    config.duration = config?.duration || 5000;
    return config;
  }
}

export enum SNACK_BAR_TYPE {
  WELCOME = 'WELCOME',
  WARNING = 'WARNING',
  INFO = 'INFO',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS'
}

export enum SNACK_BAR_IMAGE_URL {
  WELCOME = 'assets/images/snackbar/talenlio-logo-white-fill.png',
  WARNING = 'assets/images/snackbar/warning.png',
  INFO = 'assets/images/snackbar/info.png',
  DANGER = 'assets/images/snackbar/danger.png',
  SUCCESS = 'assets/images/snackbar/success.png'
}

export class LibSnackBarConfig {
  message: string;
  type: SNACK_BAR_TYPE;
  imageUrl: string;
  duration: number = 4000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  snackBarRef?: MatSnackBar;
  panelClass: string[];

  constructor(message: string = 'Excited, Coming soon',
              snackbarType: SNACK_BAR_TYPE = SNACK_BAR_TYPE.INFO) {
    this.initSnackBarConfig(message, snackbarType);
  }

  private initSnackBarConfig(message: string,
                             snackbarType: SNACK_BAR_TYPE): void {
    this.message = message;
    this.type = snackbarType;
    this.imageUrl = SNACK_BAR_IMAGE_URL[snackbarType];
    this.panelClass = LibSnackbarUtil.getPanelClass(snackbarType);
  }
}
