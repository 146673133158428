import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {NgClass, NgTemplateOutlet} from "@angular/common";
import {BUTTON_SUB_TYPE, BUTTON_TYPE, LibButtonComponent} from "../../button/lib-button/lib-button.component";

@Component({
    selector: 'lib-dialog',
    standalone: true,
    imports: [
        MatDialogContent,
        NgClass,
        LibButtonComponent,
        NgTemplateOutlet
    ],
    templateUrl: './lib-dialog.component.html',
    styleUrls: ['./lib-dialog.component.scss']
})
export class LibDialogComponent implements OnInit {

    @Input() headerTitle: string;
    @Input() headerSubTitle: string;
    @Input() headerIcon: string;
    @Input() secondaryButtonIcon = null;
    @Input() secondaryButtonText = null;
    @Input() secondaryButtonDisabled: boolean = false;
    @Input() primaryButtonIcon = null;
    @Input() primaryButtonText = null;
    @Input() primaryButtonType = BUTTON_TYPE.BASIC;
    @Input() secondaryButtonType = BUTTON_TYPE.BASIC;
    @Input() primaryButtonDisabled: boolean = false;
    @Input() dangerButtonIcon = null;
    @Input() dangerButtonText = null;
    @Input() imageUrl = null;
    @Input() hideCloseIcon = false;
    @Input() isSecondaryButtonClickedEvent = false;
    @Input() customTemplateRef?: TemplateRef<any>;
    @Input() footerTemplateRef?: TemplateRef<any>;
    @Output() primaryButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() secondaryButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() dangerButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    protected readonly BUTTON_SUB_TYPE = BUTTON_SUB_TYPE;

    constructor(public dialogRef: MatDialogRef<any>) {
    }

    ngOnInit(): void {
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    primaryButtonClicked(): void {
        this.primaryButtonClick.emit(true);
    }

    secondaryButtonClicked(): void {
        this.secondaryButtonClick.emit(true);
    }

    dangerButtonClicked(): void {
        this.dangerButtonClick.emit(true);
    }
}
