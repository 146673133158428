<div class="grid-container">
    <header class="header">
        <ng-template [ngTemplateOutlet]="headerRef"></ng-template>
    </header>
    <aside class="sidebar slide-in-left">
        <div class="flex flex-col white-square-card-container w-full p-3 h-full left-action-panel overflow-y-auto">
            <tal-left-action-panel></tal-left-action-panel>
        </div>
    </aside>
    <main class="main zoom-in">
        <div class="col-span-10 sm:col-span-6 w-full h-96 pt-12 pb-12 px-2 overflow-y-auto resume-height">
            <tal-resume></tal-resume>
        </div>
    </main>
    <aside class="aside slide-in-right">
        <div class="flex flex-col white-square-card-container
      w-full h-full right-action-panel overflow-y-auto">
            <tal-right-action-panel></tal-right-action-panel>
        </div>
    </aside>
    <footer class="footer animate-slide-up">
        <ng-template [ngTemplateOutlet]="footerRef"></ng-template>
    </footer>
</div>

<ng-template #headerRef>
    <div class="flex flex-row justify-center w-full py-4 px-5 box-shadow bg-white top-bar">
        <div class="hidden md:block absolute left-2 top-3">
            <lib-button iconClass="ti ti-arrow-narrow-left text-2xl"
                        [libSubType]="BUTTON_SUB_TYPE.SECONDARY"
                        (buttonClick)="navigateToUploadResume()">
                Upload new resume
            </lib-button>
        </div>
        <div class="flex flex-row items-center gap-2 whitespace-nowrap">
            <img class="h-4 w-4 sm:h-8 sm:w-8" src="assets/images/talenlio-small-smile-logo.png"/>
            <div class="flex flex-row items-center text-heading font-medium text-xs sm:text-xl">
                Your Resume looks Fantastic! <span class="text-lg sm:text-3xl">✨</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #footerRef>
    <tal-ai-resume-footer></tal-ai-resume-footer>
</ng-template>
