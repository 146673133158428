import {Component, EventEmitter, Output} from '@angular/core';
import {NgxFileDropEntry, NgxFileDropModule} from "ngx-file-drop";
import {BUTTON_SUB_TYPE, BUTTON_TYPE, LibButtonComponent} from "../button/lib-button/lib-button.component";

@Component({
    selector: 'lib-file-drag-drop',
    standalone: true,
    imports: [
        NgxFileDropModule,
        LibButtonComponent
    ],
    templateUrl: './lib-file-drag-drop.component.html',
    styleUrl: './lib-file-drag-drop.component.scss'
})
export class LibFileDragDropComponent {
    @Output() onSubmitFile = new EventEmitter<File>();

    file: File;
    errorMessage: string;
    protected readonly BUTTON_SUB_TYPE = BUTTON_SUB_TYPE;
    protected readonly BUTTON_TYPE = BUTTON_TYPE;

    constructor() {
    }

    droppedFiles(files: NgxFileDropEntry[]): void {
        const droppedFile = files[0];
        if (droppedFile?.fileEntry?.isFile) {
            const fileEntry = droppedFile?.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                if (this.checkIsPdfFileSelected(file)) {
                    this.file = file;
                    this.onSubmitFile.emit(this.file);
                    this.errorMessage = null;
                } else {
                    this.errorMessage = 'Only PDF files are allowed.';
                    setTimeout(() => {
                        this.errorMessage = null;
                    }, 2000);
                }
            });
        }
    }

    checkIsPdfFileSelected(file: File): boolean {
        return file.type === 'application/pdf';
    }
}
