import {CircleProgressBarModel} from "./lib-circle-score-bar.component";

export class LibCircleProgressBarUtil {
  static getCircleProgressBarModel(): CircleProgressBarModel {
    return {
      "backgroundPadding": 50,
      "radius": 59,
      "space": -10,
      "maxPercent": 1000,
      "unitsFontSize": "67",
      "unitsFontWeight": "500",
      "outerStrokeGradient": true,
      "outerStrokeWidth": 10,
      "outerStrokeColor": "var(--progress-bar-stroke-color)",
      "outerStrokeGradientStopColor": "var(--color-primary)",
      "innerStrokeColor": "var(--progress-bar-secondary-color)",
      "innerStrokeWidth": 10,
      "titleFontSize": "41",
      "titleFontWeight": "900",
      "subtitleFontSize": "21",
      "subtitleFontWeight": "600",
      "imageHeight": 185,
      "animateTitle": false,
      "animationDuration": 1000,
      "showUnits": false,
      "showBackground": false,
      "clockwise": true,
      "startFromZero": false,
      "lazy": true
    } as CircleProgressBarModel;
  }
}
