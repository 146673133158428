import {Component, Inject} from '@angular/core';
import {LibDialogComponent} from "../../../../../library/dialog/lib-dialog/lib-dialog.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import {ROUTE} from "../../../../../common/route-constants/route";
import {ResumeOutDto} from "../../../../resume/common/resume.model";
import {NavigationUtil} from "../../../common/util/navigation.util";
import {LibButtonComponent} from "../../../../../library/button/lib-button/lib-button.component";

@Component({
    selector: 'tal-resume-personalised-link-share',
    standalone: true,
    imports: [
        LibDialogComponent,
        LibButtonComponent
    ],
    templateUrl: './resume-personalised-link-share.component.html',
    styleUrl: './resume-personalised-link-share.component.scss'
})
export class ResumePersonalisedLinkShareComponent {

    title = 'Share your Website';
    subtitle = 'Personalised Link';
    publicUrl: string;
    private resumeOutDto: ResumeOutDto;

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {
        this.setDialogData();
    }

    setDialogData(): void {
        this.resumeOutDto = this.dialogData?.resumeOutDto;
        this.publicUrl = environment.showcaseBaseAppUrl + ROUTE.SLASH + ROUTE.RESUME + ROUTE.SLASH + this.resumeOutDto?.urlHandle;
    }

    onCopyButtonClick(): void {
        NavigationUtil.navigateToStandoutWebapp(this.resumeOutDto, environment.standoutBaseAppUrl);
    }
}
