import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BUTTON_SUB_TYPE, BUTTON_TYPE, LibButtonComponent} from "../../button/lib-button/lib-button.component";

@Component({
    selector: 'lib-user-focus-cta-card',
    standalone: true,
    imports: [
        LibButtonComponent
    ],
    templateUrl: './lib-user-focus-cta-card.component.html',
    styleUrls: ['./lib-user-focus-cta-card.component.scss']
})
export class LibUserFocusCtaCardComponent implements OnInit {

    @Input()
    title: string;
    @Input()
    subTitle: string;
    @Input()
    cardImage: string;
    @Input()
    talButtonType: BUTTON_TYPE = BUTTON_TYPE.LARGE;
    @Input()
    talButtonSubType: BUTTON_SUB_TYPE = BUTTON_SUB_TYPE.PRIMARY;
    @Input()
    buttonText: string;

    @Output() buttonClick = new EventEmitter();
    @Output() cardClick = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(event: Event): void {
        this.buttonClick.emit(event);
    }

    onClickCard(mouseEvent: MouseEvent): void {
        this.cardClick.emit(mouseEvent);
    }
}
