<div (click)="onClickCard($event)"
     class="card-container w-full h-full flex flex-row justify-between items-end">
    <div class="flex flex-col card-container-right p-6 w-full">
        <div class="title break-words mb-2">{{ title }}</div>
        <div class="sub-title break-words mb-6 ">{{ subTitle }}</div>
        @if (buttonText) {
            <lib-button class="w-full"
                        [libType]="talButtonType"
                        [libSubType]="talButtonSubType"
                        (buttonClick)="onSubmit($event)">
                {{ buttonText }}
            </lib-button>
        }
    </div>
    @if (cardImage) {
        <div class="card-image-container">
            <img src="{{cardImage}}" alt="card-image">
        </div>
    }
</div>
