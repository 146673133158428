<div class="flex flex-col justify-start items-start w-full">
    <div class="w-full flex-grow">
        <div class="flex flex-col relative w-full">
            <div class="m-auto w-9/12 max-w-md h-fit">
                @if (isBrowser) {
                    <ng-lottie class="w-full object-cover"
                               [options]="lottieAnimationOptions">
                    </ng-lottie>
                }
            </div>
            <div class="flex items-center justify-center
                        text-black py-4 text-3xl sm:text-5xl text-heading">
                {{ loadingPercentage }}%
            </div>
            <div
                class="text-heading text-sm sm:text-lg text-center max-w-80 sm:max-w-full break-words sm:whitespace-nowrap sm:w-full mb-2">
                <div class="animation-fade">{{ currentMessage }}</div>
            </div>
            <div class="text-heading-secondary text-xs sm:text-sm text-center">
                Please don’t reload or navigate away while we craft your resume
            </div>
        </div>
    </div>
</div>
