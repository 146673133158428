import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {ROUTE} from '../../../../../common/route-constants/route';
import {
    LibSnackBarConfig,
    LibSnackbarService,
    SNACK_BAR_TYPE
} from '../../../../../library/snackbar/lib-snackbar/common/service/lib-snackbar.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'tal-ai-resume-footer',
    standalone: true,
    imports: [
        LottieComponent
    ],
    templateUrl: './ai-resume-footer.component.html',
    styleUrl: './ai-resume-footer.component.scss'
})
export class AiResumeFooterComponent implements OnInit {
    lottieAnimationOptions: AnimationOptions = {
        path: '/assets/lottie/two-right-arrow.json',
    };
    isBrowser: boolean = false;

    constructor(private libSnackbarService: LibSnackbarService,
                private clipboard: Clipboard,
                @Inject(PLATFORM_ID) private platformId: Object) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnInit(): void {
    }

    onClickFooter(): void {
        this.clipboard.copy(`${environment.showcaseBaseAppUrl}/${ROUTE.FIX_RESUME}`);
        this.libSnackbarService.openSnackBar(new LibSnackBarConfig('Copied To Clipboard', SNACK_BAR_TYPE.SUCCESS));
    }
}
