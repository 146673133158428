import 'firebase/database';
import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagFirebaseApiService {
    private supportUrl = '/support';

    constructor(private firebaseDatabase: AngularFireDatabase) {
    }

    getSupportModel(subdomain: string) {
        const featureFlagFirebase = this.firebaseDatabase.database.app.database(environment.featureFlagFirebase.databaseURL)
            .ref(`${this.supportUrl}/${subdomain}`);
        return this.firebaseDatabase.object(featureFlagFirebase).valueChanges();
    }
}
