import {AiResumeUploadComponent} from "./ai-resume-upload/ai-resume-upload.component";
import {Routes} from "@angular/router";
import {AiResumeLoadingComponent} from "./ai-resume-loading/ai-resume-loading.component";
import {AiResumeBuilderComponent} from "./ai-resume-builder/ai-resume-builder.component";
import {ROUTE} from "../../common/route-constants/route";
import {AiResumeComponent} from "./ai-resume.component";

export const AI_RESUME_ROUTE: Routes = [
  {
    path: '',
    component: AiResumeComponent,
    children: [
      {
        path: '',
        component: AiResumeUploadComponent
      },
      {
        path: ROUTE.LOADING,
        component: AiResumeLoadingComponent
      }
    ]
  },
  {
    path: ROUTE.AI_RESUME_BUILDER + ROUTE.SLASH + ROUTE.COLON + ROUTE.RESUME_ID,
    component: AiResumeBuilderComponent
  }
];
