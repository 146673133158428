<div class="flex flex-col min-h-screen bg-cover bg-center fix-resume-container">
    <div class="py-4 px-5 box-shadow">
        <img src="assets/talenlio-logo.png" class="w-36 h-8">
    </div>
    <div class="flex-1 flex justify-center items-center">
        <router-outlet></router-outlet>
    </div>
    <div class="animate-slide-up">
        <tal-ai-resume-footer></tal-ai-resume-footer>
    </div>
</div>
