import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';
import {interval, Subscription} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';


@Component({
    selector: 'tal-ai-resume-loading',
    standalone: true,
    imports: [
        LottieComponent
    ],
    templateUrl: './ai-resume-loading.component.html',
    styleUrl: './ai-resume-loading.component.scss',
})
export class AiResumeLoadingComponent implements OnInit, OnChanges, OnDestroy {

    @Input() loadingPercentage = 0;

    lottieAnimationOptions: AnimationOptions = {
        path: '/assets/lottie/ai-style-loading.json',
    };
    isBrowser: boolean = false;

    // TODO : will move in next mr later
    messages: string[] = [
        `Polishing your story, one word at a time...`,
        `Crafting your career journey...`,
        `Fine-tuning your professional narrative...`,
        `Turning your experiences into opportunities...`,
        `Shaping your future, just a moment longer...`,
        `Your resume is getting ready to shine...`,
        `Highlighting your achievements...`,
        `Preparing the perfect showcase for your skills...`,
        `Turning your potential into possibilities...`,
        `Almost there... making sure every detail counts...`
    ];

    currentMessage = this.messages[0];
    private messageSubscription: Subscription;

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['loadingPercentage']?.currentValue) {
            this.loadingPercentage = changes['loadingPercentage'].currentValue;
        }
    }

    ngOnInit(): void {
        let index = 0;
        this.messageSubscription = interval(5000).subscribe(() => {
            index = (index + 1) % this.messages.length;
            this.currentMessage = this.messages[index];
        });
    }

    ngOnDestroy(): void {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }
    }
}
