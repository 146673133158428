<div class="mb-4">
  <div class="text-base text-heading-light font-medium mr-2">
    Resume Score
  </div>
  <div (click)="clicked()"
       class="grid place-content-center relative overflow-hidden w-max mx-auto items-center cursor-pointer">
    <div class="m-auto">
      <circle-progress [animationDuration]="animationDuration"
                       [animation]="true"
                       [innerStrokeWidth]="innerStrokeWidth"
                       [outerStrokeWidth]="outerStrokeWidth"
                       [percent]="percent"
                       [radius]="radius"
                       [space]="space"
                       innerStrokeColor="var(--progress-bar-secondary-color)"
                       outerStrokeColor="var(--progress-bar-color)">
      </circle-progress>
    </div>
    <div class="flex flex-col items-center justify-center absolute bg-white rounded-full resume-score-content">
      <div class="text-heading-primary">Score</div>
      <div class="text-xl font-bold font-poppins text-center text-heading-secondary m-0">{{ percent }}%</div>
      @if (isShowRecalculate) {
        <div class="text-sm font-bold font-poppins text-center text-heading-primary cursor-pointer"
             (click)="recalculateClicked($event)">
          Recalculate
        </div>
      }
    </div>
  </div>
</div>
