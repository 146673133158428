<ngx-file-drop dropZoneLabel="Drop files here"
               (onFileDrop)="droppedFiles($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="flex flex-col items-center gap-4 p-4 relative overflow-hidden">
            <img class="w-8 h-8 sm:w-12 sm:h-12 m-auto" src="assets/images/upload-file.png">
            <div class="text-sm">Drag your file to start uploading</div>
            <div class="flex flex-row w-full items-center justify-center gap-3">
                <span class="border-solid border-b-2 w-full"></span>
                <span class="text-heading text-xs">OR</span>
                <span class="border-solid border-b-2 w-full"></span>
            </div>
            <lib-button [libSubType]="BUTTON_SUB_TYPE.SECONDARY"
                        [libType]="BUTTON_TYPE.BASIC"
                        (buttonClick)="openFileSelector()">
                Browse Files
            </lib-button>
            @if (errorMessage) {
                <div
                    class="absolute text-danger font-medium text-xs z-20 bottom-0 animate-slide-up">{{ errorMessage }}
                </div>
            }
        </div>
    </ng-template>
</ngx-file-drop>
