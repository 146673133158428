<div class="mb-4">
  <div class="text-base text-heading-light font-medium mr-2">
    Column Layout
  </div>
  <div class="grid grid-cols-2 border border-stroke rounded-lg mt-2 w-full">
    @for (layoutSelectionModel of layoutSelectionModels; track layoutSelectionModel?.layoutType) {
      <ng-container>
        <div class="flex flex-row items-center justify-center m-0.5 cursor-pointer"
             [ngClass]="{'inactive-button-layout-button':layoutSelectionModel?.layoutType != currentLayoutType,'active-button-layout-button':layoutSelectionModel.layoutType == currentLayoutType}"
             (click)="onClick(layoutSelectionModel)">
          <i class="text-2xl {{layoutSelectionModel?.imageUrl}}"
             [ngClass]="{'inactive-button-layout-button':layoutSelectionModel?.layoutType != currentLayoutType,'active-section':layoutSelectionModel.layoutType == currentLayoutType}"></i>
          <div class="text-xs text-heading font-normal"
               [ngClass]="{'active-section':layoutSelectionModel?.layoutType == currentLayoutType}">
            {{ LAYOUT_TYPE_DISPLAY[layoutSelectionModel?.layoutType] }}
          </div>
        </div>
      </ng-container>
    }
  </div>
</div>
