@if (isLoading) {
    <tal-ai-resume-loading [loadingPercentage]="uploadProgress"></tal-ai-resume-loading>
} @else {
    <div class="flex flex-col sm:flex-row justify-center items-center w-full h-full sm:p-14">
        <div class="upload-resume-left-container mb-8 sm:mb-0">
            <h2 class="text-heading text-center sm:text-start text-wrap text-4xl sm:text-6xl font-semibold">
                Fix Your Resume with AI in Minutes!
            </h2>
            <p class="text-subheading text-wrap mt-3 sm:mt-6 text-center sm:text-start">
                Get 5x more interviews
            </p>
        </div>
        <div
            class="flex flex-col gap-2 justify-center items-center white-card-shadow-container sm:max-h-72 h-fit sm:w-96 p-4 animate-bounce">
            <div class="center w-full h-full">
                <lib-file-drag-drop (onSubmitFile)="onSubmitFile($event)">
                </lib-file-drag-drop>
            </div>
            <p class="text-xs text-subheading">
                Only supports PDF file
            </p>
        </div>
    </div>
}
