import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResumeOutDto} from "../../../resume/common/resume.model";
import {Subscription} from "rxjs";
import {ResumeStore} from "../../common/store/resume.store";
import {
    BUTTON_SUB_TYPE,
    BUTTON_TYPE,
    LibButtonComponent
} from "../../../../library/button/lib-button/lib-button.component";
import {environment} from "../../../../../environments/environment";
import {
    LibUserFocusCtaCardComponent
} from "../../../../library/card/lib-user-focus-cta-card/lib-user-focus-cta-card.component";
import {NavigationUtil} from "../../common/util/navigation.util";
import {
    LibDialogConfigModel,
    LibDialogOpenService
} from "../../../../library/dialog/common/service/lib-dialog-open.service";
import {
    ResumePersonalisedLinkShareComponent
} from "./resume-personalised-link-share/resume-personalised-link-share.component";
import {LinkService} from "../../../../common/util/link.service";
import {FeatureFlagStore, SupportModel} from "../../../../common/store/feature-flag-store.service";

@Component({
    selector: 'tal-right-action-panel',
    standalone: true,
    imports: [
        LibButtonComponent,
        LibUserFocusCtaCardComponent
    ],
    templateUrl: './right-action-panel.component.html',
    styleUrl: './right-action-panel.component.scss'
})
export class RightActionPanelComponent implements OnInit, OnDestroy {
    protected readonly BUTTON_TYPE = BUTTON_TYPE;
    protected readonly BUTTON_SUB_TYPE = BUTTON_SUB_TYPE;
    private resumeOutDto: ResumeOutDto;
    private supportModel: SupportModel;
    private subscription = new Subscription();

    constructor(private resumeStore: ResumeStore,
                private featureFlagStore: FeatureFlagStore,
                private libDialogOpenService: LibDialogOpenService,
                private linkService: LinkService) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
        this.subscribeToFeatureFlagStore();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    openShareLinkDialog(): void {
        this.libDialogOpenService.open(ResumePersonalisedLinkShareComponent, {
            autoFocus: false,
            disableClose: true,
            width: "55%",
            data: {
                resumeOutDto: this.resumeOutDto
            }
        } as LibDialogConfigModel);
    }

    redirectToResumeBuilder(): void {
        NavigationUtil.navigateToStandoutWebapp(this.resumeOutDto, environment.standoutBaseAppUrl);
    }

    navigateToCalendly(): void {
        this.linkService.openLinkInNewTab(this.supportModel.bookCallUrl);
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto.subscribe(resumeOutDto => {
            if (resumeOutDto) {
                this.resumeOutDto = resumeOutDto;
            }
        }));
    }

    private subscribeToFeatureFlagStore(): void {
        this.subscription.add(this.featureFlagStore.supportModel.subscribe(supportModel => {
            if (supportModel) {
                this.supportModel = supportModel;
            }
        }));
    }
}
