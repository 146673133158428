import {Component} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {AiResumeFooterComponent} from "./common/component/ai-resume-footer/ai-resume-footer.component";

@Component({
    selector: 'tal-ai-resume',
    standalone: true,
    imports: [
        RouterOutlet,
        AiResumeFooterComponent
    ],
    templateUrl: './ai-resume.component.html',
    styleUrl: './ai-resume.component.scss'
})
export class AiResumeComponent {
}
