import {Component, EventEmitter, Input, ModuleWithProviders, OnInit, Output} from '@angular/core';
import {NgCircleProgressModule} from "ng-circle-progress";
import {LibCircleProgressBarUtil} from "./lib-circle-progress-bar.util";

@Component({
  selector: 'lib-circle-score-bar',
  standalone: true,
  imports: [
    NgCircleProgressModule
  ],
  providers: [
    (NgCircleProgressModule.forRoot(LibCircleProgressBarUtil.getCircleProgressBarModel()) as ModuleWithProviders<NgCircleProgressModule>).providers!,
  ],
  templateUrl: './lib-circle-score-bar.component.html',
  styleUrl: './lib-circle-score-bar.component.scss'
})
export class LibCircleScoreBarComponent implements OnInit {
  @Input() percent: number = 0;
  @Input() animationDuration: number = 1000;
  @Input() space: number = -10;
  @Input() radius: number = 70;
  @Input() outerStrokeWidth: number = 10;
  @Input() innerStrokeWidth: number = 12;
  @Input() isShowRecalculate: boolean = true;
  @Output() onRecalculate = new EventEmitter();
  @Output() onClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  recalculateClicked(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
    this.onRecalculate.emit();
  }

  clicked() {
    this.onClick.emit();
  }
}

export class CircleProgressBarModel {
  backgroundPadding?: number;
  radius?: number;
  space?: number;
  maxPercent?: number;
  unitsFontSize?: string;
  unitsFontWeight?: string;
  outerStrokeGradient?: boolean;
  outerStrokeWidth?: number;
  outerStrokeColor?: string;
  outerStrokeGradientStopColor?: string;
  innerStrokeColor?: string;
  innerStrokeWidth?: number;
  titleFontSize?: string;
  titleFontWeight?: string;
  subtitleFontSize?: string;
  subtitleFontWeight?: string;
  imageHeight?: number;
  animateTitle?: boolean;
  animationDuration?: number;
  showUnits?: boolean;
  showBackground?: boolean;
  clockwise?: boolean;
  startFromZero?: boolean;
  lazy?: boolean;
}
