import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {SubDomainStore} from "./sub-domain-store.service";
import {ROUTE} from "../route-constants/route";
import {FeatureFlagFirebaseApiService} from "../../firebase/common/service/feature-flag-firebase-api.service";

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagStore {

    public supportModel: Observable<SupportModel>;
    private _supportModel = new BehaviorSubject<SupportModel>(null);
    private subdomain: string;

    constructor(private subDomainStore: SubDomainStore,
                private featureFlagFirebaseApiService: FeatureFlagFirebaseApiService) {
        this.supportModel = this._supportModel.asObservable();
        this.subscribeSubdomain();
        this.loadSupport(this.subdomain);
    }

    loadSupport(subdomain?: string): void {
        this.featureFlagFirebaseApiService.getSupportModel(ROUTE.SUBDOMAIN + '_' + subdomain.toUpperCase()).subscribe(supportModel => {
            if (supportModel) {
                this.publishSupport(supportModel as SupportModel);
            } else {
                this.loadSupport(ROUTE.DEFAULT);
            }
        });
    }

    private publishSupport(supportModel: SupportModel): void {
        this._supportModel.next(supportModel);
    }

    private subscribeSubdomain(): void {
        this.subDomainStore.subdomain.subscribe(subdomain => {
            this.subdomain = subdomain;
        });
    }
}

export class SupportModel {
    bookCallUrl: string;
}
