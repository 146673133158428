import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";
import {NgClass} from "@angular/common";
import {LibSnackbarUtil} from "./common/util/lib-snackbar.util";
import {LibSnackBarConfig, SNACK_BAR_TYPE} from './common/service/lib-snackbar.service';

@Component({
  selector: 'lib-snackbar',
  standalone: true,
    imports: [
        NgClass
    ],
  templateUrl: './lib-snackbar.component.html',
  styleUrls: ['./lib-snackbar.component.scss']
})
export class LibSnackbarComponent implements OnInit {

  SNACK_BAR_TYPE = SNACK_BAR_TYPE;
  protected readonly LibSnackbarUtil = LibSnackbarUtil;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public snackBarConfig: LibSnackBarConfig) {
  }

  ngOnInit(): void {
  }

  onCloseSnackBar(): void {
    this.snackBarConfig.snackBarRef.dismiss();
  }
}
