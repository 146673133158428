<div class="min-h-screen relative">
    <div class="sticky top-6">
        <lib-circle-score-bar [isShowRecalculate]="false"
                              [percent]="resumeOutDto?.resumeScore">
        </lib-circle-score-bar>
        <tal-ats-layout-selection [atsLayoutSelectionModels]="atsLayoutSelectionModels"
                                  [currentLayoutType]="resumeOutDto?.atsLayout"
                                  (onChangeATSLayout)="setAtsSelectionType($event)">
        </tal-ats-layout-selection>
        @if (resumeOutDto?.atsLayout === ATS_LAYOUT_TYPE.MODERN) {
            <tal-resume-layout-selection [layoutSelectionModels]="layoutSelectionModels"
                                         [currentLayoutType]="resumeOutDto?.layoutType"
                                         (layoutTypeEvent)="setLayoutSelectionType($event)">
            </tal-resume-layout-selection>
            <resume-theme-indicator [resumeThemeType]="selectedResumeThemeType"

                                    (selectedTheme)="updateResumeTheme($event)">
            </resume-theme-indicator>
        }
    </div>
</div>
