import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpProgressEvent} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    private apiUrl = 'https://integration-prod.talenlio.com/webhook/fix-resume-v2';

    constructor(private httpClient: HttpClient) {
    }

    uploadPdf(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('data', file, file.name);

        let params = new HttpParams();
        if (environment.name !== 'prod') {
            params = params.set('env', 'staging');
        }

        const headers = new HttpHeaders({'Accept': 'application/json'});

        return this.httpClient.post<HttpProgressEvent>(this.apiUrl, formData, {
            params,
            headers,
            reportProgress: true,
            observe: 'events'
        });
    }
}
