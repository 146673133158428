import {Injectable} from '@angular/core';
import {ComponentType} from "@angular/cdk/overlay";
import {DialogPosition, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Direction} from "@angular/cdk/bidi";
import {DialogRole} from "@angular/cdk/dialog";

@Injectable({
    providedIn: 'root'
})
export class LibDialogOpenService {

    constructor(private matDialog: MatDialog) {
    }

    open(component: ComponentType<any>, libDialogConfigModel?: LibDialogConfigModel): MatDialogRef<any> {
        let defaultConfig = this.mergeTalDialogConfig(libDialogConfigModel);
        return this.matDialog.open(component, {
            width: defaultConfig?.width,
            height: defaultConfig?.height,
            minWidth: defaultConfig?.minWidth,
            minHeight: defaultConfig?.minHeight,
            maxWidth: defaultConfig?.maxWidth,
            maxHeight: defaultConfig?.maxHeight,
            position: defaultConfig?.position,
            data: defaultConfig?.data,
            direction: defaultConfig?.direction,
            autoFocus: defaultConfig?.autoFocus,
            panelClass: defaultConfig?.panelClass,
            role: defaultConfig?.role,
            disableClose: defaultConfig?.disableClose
        });
    }

    private mergeTalDialogConfig(libDialogConfigModel: LibDialogConfigModel): LibDialogConfigModel {
        let defaultConfig: LibDialogConfigModel = new LibDialogConfigModel();
        defaultConfig = {
            ...defaultConfig,
            ...libDialogConfigModel,
        };
        return defaultConfig;
    }
}

export class LibDialogConfigModel {
    disableClose: boolean = true;
    panelClass?: string | string[];
    role?: DialogRole;
    width: string = '50%';
    height?: string;
    minWidth?: number | string;
    minHeight?: number | string;
    maxWidth?: number | string;
    maxHeight?: number | string;
    position?: DialogPosition;
    data: any = null;
    direction?: Direction;
    autoFocus: boolean = true;
}
