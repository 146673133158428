<div class="flex flex-col justify-between p-2.5 h-full bg-white relative min-h-screen">
    <div class="flex-1 flex flex-col items-start justify-between">
        <div class="w-full flex flex-col items-start gap-4 sticky top-6 z-20">
            <lib-button class="w-full"
                        iconClass="ti ti-download text-2xl"
                        [libSubType]="BUTTON_SUB_TYPE.PRIMARY"
                        [libType]="BUTTON_TYPE.LARGE"
                        (buttonClick)="redirectToResumeBuilder()">
                Download Pdf
            </lib-button>
            <lib-button class="w-full"
                        iconClass="ti ti-link text-2xl"
                        [libSubType]="BUTTON_SUB_TYPE.SECONDARY"
                        [libType]="BUTTON_TYPE.LARGE"
                        (buttonClick)="openShareLinkDialog()">
                Create sharable link
            </lib-button>
            <lib-button class="w-full"
                        iconClass="ti ti-edit text-2xl"
                        [libSubType]="BUTTON_SUB_TYPE.SECONDARY"
                        [libType]="BUTTON_TYPE.LARGE"
                        (buttonClick)="redirectToResumeBuilder()">
                Go to editor
            </lib-button>
        </div>
        <div class="resume-image-container cursor-pointer my-2 w-full sticky bottom-6 z-20">
            <lib-user-focus-cta-card title="Need Guidance?"
                                     subTitle="Connect with our industry experts & boost interview calls by 5x"
                                     buttonText="Book a free call now!"
                                     [talButtonSubType]="BUTTON_SUB_TYPE.SECONDARY"
                                     (cardClick)="navigateToCalendly()">
            </lib-user-focus-cta-card>
        </div>
    </div>
</div>
