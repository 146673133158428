<lib-dialog [headerTitle]="title"
            [headerSubTitle]="subtitle">
    <div class="flex flex-col sm:flex-row justify-center sm:justify-between items-end sm:items-start gap-2 py-4 w-full">
        <div
            class="rounded-xl w-full border-stroke min-h-12 flex justify-start items-center gap-1 base-url-background text-sm
            text-heading-primary font-normal cursor-not-allowed break-all whitespace-normal">
            @if (publicUrl) {
                <span class="ml-1">
                    {{ publicUrl }}
                </span>
            }
        </div>
        <lib-button iconClass="ti ti-copy text-xl"
                    (buttonClick)="onCopyButtonClick()">
            Copy Link
        </lib-button>
    </div>
</lib-dialog>
