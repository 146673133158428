import {environment} from "../../../../../environments/environment";
import {ResumeOutDto} from "../../../resume/common/resume.model";
import {ROUTE} from "../../../../common/route-constants/route";

export class NavigationUtil {

    static navigateToStandoutWebapp(resumeOutDto: ResumeOutDto, standoutBaseUrl: string): void {
        const emailAddress: string = encodeURIComponent(resumeOutDto?.personalInformation?.emailAddress || '');
        const fullName: string = encodeURIComponent(resumeOutDto?.personalInformation?.fullName || '');
        const phone: string = encodeURIComponent((resumeOutDto?.personalInformation?.phone || ''));
        window.location.href = standoutBaseUrl + `/${ROUTE.FORM}/${environment.fixMyResumeBatchId}/${ROUTE.VERIFY_EMAIL}?${ROUTE.EMAIL}=${emailAddress}&${ROUTE.FULL_NAME}=${fullName}&${ROUTE.PHONE}=${phone}&${ROUTE.REDIRECT_URL = ROUTE.RESUMES}`;
    }
}
