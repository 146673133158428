import {Component, OnDestroy} from '@angular/core';
import {NgxFileDropModule} from "ngx-file-drop";
import {LibFileDragDropComponent} from "../../../library/lib-file-drag-drop/lib-file-drag-drop.component";
import {FileUploadService} from "../common/file-upload.service";
import {Router} from "@angular/router";
import {HttpEventType} from "@angular/common/http";
import {AiResumeLoadingComponent} from "../ai-resume-loading/ai-resume-loading.component";
import {ROUTE} from "../../../common/route-constants/route";
import {
    LibSnackBarConfig,
    LibSnackbarService,
    SNACK_BAR_TYPE
} from "../../../library/snackbar/lib-snackbar/common/service/lib-snackbar.service";

@Component({
    selector: 'tal-ai-resume-upload',
    standalone: true,
    imports: [
        NgxFileDropModule,
        LibFileDragDropComponent,
        AiResumeLoadingComponent
    ],
    templateUrl: './ai-resume-upload.component.html',
    styleUrl: './ai-resume-upload.component.scss'
})
export class AiResumeUploadComponent implements OnDestroy {

    uploadProgress = 0;
    isLoading: boolean;
    private responseReceived = false;
    private intervalId: any;
    private response: any;

    constructor(private router: Router,
                private fileUploadService: FileUploadService,
                private libSnackbarService: LibSnackbarService) {
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            this.clearInterval();
        }
    }

    onSubmitFile(file: File): void {
        this.onUpload(file);
    }

    private onUpload(file: File): void {
        this.startLoading();
        this.fileUploadService.uploadPdf(file).subscribe((event) => {
            if (event.type === HttpEventType.Response) {
                this.response = event?.body;
                this.markResponseReceived();
            }
        }, (error) => {
            this.handleError();
        });
    }

    private startLoading(): void {
        this.isLoading = true;
        this.responseReceived = false;
        this.intervalId = setInterval(() => {
            if (this.uploadProgress < 90 && !this.responseReceived) {
                this.uploadProgress += 1;
            } else if (this.responseReceived) {
                this.uploadProgress += 10;
                if (this.uploadProgress >= 100) {
                    this.completeLoading();
                }
            }
        }, 500);
    }

    private markResponseReceived() {
        this.responseReceived = true;
        if (this.uploadProgress >= 90) {
            this.completeLoading();
        }
    }

    private completeLoading() {
        this.uploadProgress = 100;
        this.clearInterval();
        let resumeId = (this.response)?.id;
        if (resumeId) {
            this.navigateToFixResume(resumeId);
        }
    }

    private handleError() {
        this.isLoading = false;
        this.uploadProgress = 0;
        this.clearInterval();
        this.intervalId = null;
        this.libSnackbarService.openSnackBar(new LibSnackBarConfig("Not a valid PDF document of Resume", SNACK_BAR_TYPE.DANGER));
    }

    private clearInterval() {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    private navigateToFixResume(resumeId: string) {
        this.router.navigate([ROUTE.FIX_RESUME, ROUTE.AI_RESUME_BUILDER, resumeId]);
    }
}
