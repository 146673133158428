import * as i0 from '@angular/core';
import { inject, NgZone, InjectionToken, EnvironmentInjector, PLATFORM_ID, Injectable, Inject, Optional, NgModule } from '@angular/core';
import * as i1 from '@angular/fire';
import { VERSION } from '@angular/fire';
import * as i3 from '@angular/fire/app-check';
import { ɵfirebaseAppFactory as _firebaseAppFactory, ɵcacheInstance as _cacheInstance, FIREBASE_OPTIONS, FIREBASE_APP_NAME } from '@angular/fire/compat';
import * as i2 from '@angular/fire/compat/auth';
import { ɵauthFactory as _authFactory, USE_EMULATOR as USE_EMULATOR$1, SETTINGS, TENANT_ID, LANGUAGE_CODE, USE_DEVICE_LANGUAGE, PERSISTENCE } from '@angular/fire/compat/auth';
import { pendingUntilEvent } from '@angular/core/rxjs-interop';
import { map, share, scan, withLatestFrom, skipWhile, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Observable, asyncScheduler, merge, of } from 'rxjs';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import firebase from 'firebase/compat/app';

/**
 * Create an observable from a Database Reference or Database Query.
 * @param ref Database Reference
 * @param event Listen event type ('value', 'added', 'changed', 'removed', 'moved')
 * @param listenType 'on' or 'once'
 * @param scheduler - Rxjs scheduler
 */
function fromRef(ref, event, listenType = 'on', scheduler = asyncScheduler) {
  return new Observable(subscriber => {
    let fn = null;
    fn = ref[listenType](event, (snapshot, prevKey) => {
      scheduler.schedule(() => {
        subscriber.next({
          snapshot,
          prevKey
        });
      });
      if (listenType === 'once') {
        scheduler.schedule(() => subscriber.complete());
      }
    }, err => {
      scheduler.schedule(() => subscriber.error(err));
    });
    if (listenType === 'on') {
      return {
        unsubscribe() {
          if (fn != null) {
            ref.off(event, fn);
          }
        }
      };
    } else {
      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        unsubscribe() {}
      };
    }
  }).pipe(map(payload => {
    const {
      snapshot,
      prevKey
    } = payload;
    let key = null;
    if (snapshot.exists()) {
      key = snapshot.key;
    }
    return {
      type: event,
      payload: snapshot,
      prevKey,
      key
    };
  }), share());
}
function isString(value) {
  return typeof value === 'string';
}
function isFirebaseDataSnapshot(value) {
  return typeof value.exportVal === 'function';
}
function isNil(obj) {
  return obj === undefined || obj === null;
}
function isFirebaseRef(value) {
  return typeof value.set === 'function';
}
/**
 * Returns a database reference given a Firebase App and an
 * absolute or relative path.
 * @param database - Firebase Database
 * @param pathRef - Database path, relative or absolute
 */
function getRef(database, pathRef) {
  // if a db ref was passed in, just return it
  return isFirebaseRef(pathRef) ? pathRef : database.ref(pathRef);
}
function checkOperationCases(item, cases) {
  if (isString(item)) {
    return cases.stringCase();
  } else if (isFirebaseRef(item)) {
    return cases.firebaseCase();
  } else if (isFirebaseDataSnapshot(item)) {
    return cases.snapshotCase();
  }
  throw new Error(`Expects a string, snapshot, or reference. Got: ${typeof item}`);
}
function validateEventsArray(events) {
  if (isNil(events) || events.length === 0) {
    events = ['child_added', 'child_removed', 'child_changed', 'child_moved'];
  }
  return events;
}
function stateChanges(query, events, scheduler) {
  events = validateEventsArray(events);
  const childEvent$ = events.map(event => fromRef(query, event, 'on', scheduler));
  return merge(...childEvent$);
}
function auditTrail(query, events, scheduler) {
  const auditTrail$ = stateChanges(query, events).pipe(scan((current, action) => [...current, action], []));
  return waitForLoaded(query, auditTrail$, scheduler);
}
function loadedData(query, scheduler) {
  // Create an observable of loaded values to retrieve the
  // known dataset. This will allow us to know what key to
  // emit the "whole" array at when listening for child events.
  return fromRef(query, 'value', 'on', scheduler).pipe(map(data => {
    // Store the last key in the data set
    let lastKeyToLoad;
    // Loop through loaded dataset to find the last key
    data.payload.forEach(child => {
      lastKeyToLoad = child.key;
      return false;
    });
    // return data set and the current last key loaded
    return {
      data,
      lastKeyToLoad
    };
  }));
}
function waitForLoaded(query, action$, scheduler) {
  const loaded$ = loadedData(query, scheduler);
  return loaded$.pipe(withLatestFrom(action$),
  // Get the latest values from the "loaded" and "child" datasets
  // We can use both datasets to form an array of the latest values.
  map(([loaded, actions]) => {
    // Store the last key in the data set
    const lastKeyToLoad = loaded.lastKeyToLoad;
    // Store all child keys loaded at this point
    const loadedKeys = actions.map(snap => snap.key);
    return {
      actions,
      lastKeyToLoad,
      loadedKeys
    };
  }),
  // This is the magical part, only emit when the last load key
  // in the dataset has been loaded by a child event. At this point
  // we can assume the dataset is "whole".
  skipWhile(meta => meta.loadedKeys.indexOf(meta.lastKeyToLoad) === -1),
  // Pluck off the meta data because the user only cares
  // to iterate through the snapshots
  map(meta => meta.actions));
}
function createDataOperationMethod(ref, operation) {
  return function dataOperation(item, value) {
    return checkOperationCases(item, {
      stringCase: () => ref.child(item)[operation](value),
      firebaseCase: () => item[operation](value),
      snapshotCase: () => item.ref[operation](value)
    });
  };
}

// TODO(davideast): Find out why TS thinks this returns firebase.Primise
// instead of Promise.
function createRemoveMethod(ref) {
  return function remove(item) {
    if (!item) {
      return ref.remove();
    }
    return checkOperationCases(item, {
      stringCase: () => ref.child(item).remove(),
      firebaseCase: () => item.remove(),
      snapshotCase: () => item.ref.remove()
    });
  };
}
function listChanges(ref, events, scheduler) {
  return fromRef(ref, 'value', 'once', scheduler).pipe(switchMap(snapshotAction => {
    const childEvent$ = [of(snapshotAction)];
    events.forEach(event => childEvent$.push(fromRef(ref, event, 'on', scheduler)));
    return merge(...childEvent$).pipe(scan(buildView, []));
  }), distinctUntilChanged());
}
function positionFor(changes, key) {
  const len = changes.length;
  for (let i = 0; i < len; i++) {
    if (changes[i].payload.key === key) {
      return i;
    }
  }
  return -1;
}
function positionAfter(changes, prevKey) {
  if (isNil(prevKey)) {
    return 0;
  } else {
    const i = positionFor(changes, prevKey);
    if (i === -1) {
      return changes.length;
    } else {
      return i + 1;
    }
  }
}
function buildView(current, action) {
  const {
    payload,
    prevKey,
    key
  } = action;
  const currentKeyPosition = positionFor(current, key);
  const afterPreviousKeyPosition = positionAfter(current, prevKey);
  switch (action.type) {
    case 'value':
      if (action.payload?.exists()) {
        let prevKey = null;
        action.payload.forEach(payload => {
          const action = {
            payload,
            type: 'value',
            prevKey,
            key: payload.key
          };
          prevKey = payload.key;
          current = [...current, action];
          return false;
        });
      }
      return current;
    case 'child_added':
      if (currentKeyPosition > -1) {
        // check that the previouskey is what we expect, else reorder
        const previous = current[currentKeyPosition - 1];
        if ((previous?.key || null) !== prevKey) {
          current = current.filter(x => x.payload.key !== payload.key);
          current.splice(afterPreviousKeyPosition, 0, action);
        }
      } else if (prevKey == null) {
        return [action, ...current];
      } else {
        current = current.slice();
        current.splice(afterPreviousKeyPosition, 0, action);
      }
      return current;
    case 'child_removed':
      return current.filter(x => x.payload.key !== payload.key);
    case 'child_changed':
      return current.map(x => x.payload.key === key ? action : x);
    case 'child_moved':
      if (currentKeyPosition > -1) {
        const data = current.splice(currentKeyPosition, 1)[0];
        current = current.slice();
        current.splice(afterPreviousKeyPosition, 0, data);
        return current;
      }
      return current;
    // default will also remove null results
    default:
      return current;
  }
}
function snapshotChanges(query, events, scheduler) {
  events = validateEventsArray(events);
  return listChanges(query, events, scheduler);
}
function createListReference(query, afDatabase, injector) {
  const outsideAngularScheduler = afDatabase.schedulers.outsideAngular;
  const refInZone = inject(NgZone).run(() => query.ref);
  return {
    query,
    update: createDataOperationMethod(refInZone, 'update'),
    set: createDataOperationMethod(refInZone, 'set'),
    push: data => refInZone.push(data),
    remove: createRemoveMethod(refInZone),
    snapshotChanges(events) {
      return snapshotChanges(query, events, outsideAngularScheduler).pipe(pendingUntilEvent(injector));
    },
    stateChanges(events) {
      return stateChanges(query, events, outsideAngularScheduler).pipe(pendingUntilEvent(injector));
    },
    auditTrail(events) {
      return auditTrail(query, events, outsideAngularScheduler).pipe(pendingUntilEvent(injector));
    },
    valueChanges(events, options) {
      const snapshotChanges$ = snapshotChanges(query, events, outsideAngularScheduler);
      return snapshotChanges$.pipe(map(actions => actions.map(a => {
        if (options && options.idField) {
          return {
            ...a.payload.val(),
            ...{
              [options.idField]: a.key
            }
          };
        } else {
          return a.payload.val();
        }
      })), pendingUntilEvent(injector));
    }
  };
}
function createObjectSnapshotChanges(query, scheduler) {
  return function snapshotChanges() {
    return fromRef(query, 'value', 'on', scheduler);
  };
}
function createObjectReference(query, afDatabase, injector) {
  return {
    query,
    snapshotChanges() {
      return createObjectSnapshotChanges(query, afDatabase.schedulers.outsideAngular)().pipe(pendingUntilEvent(injector));
    },
    update(data) {
      return query.ref.update(data);
    },
    set(data) {
      return query.ref.set(data);
    },
    remove() {
      return query.ref.remove();
    },
    valueChanges() {
      const snapshotChanges$ = createObjectSnapshotChanges(query, afDatabase.schedulers.outsideAngular)();
      return snapshotChanges$.pipe(pendingUntilEvent(injector), map(action => action.payload.exists() ? action.payload.val() : null));
    }
  };
}
const URL = new InjectionToken('angularfire2.realtimeDatabaseURL');
const USE_EMULATOR = new InjectionToken('angularfire2.database.use-emulator');
class AngularFireDatabase {
  schedulers;
  database;
  injector = inject(EnvironmentInjector);
  constructor(options, name, databaseURL,
  // eslint-disable-next-line @typescript-eslint/ban-types
  platformId, zone, schedulers, _useEmulator,
  // tuple isn't working here
  auth, useAuthEmulator, authSettings,
  // can't use firebase.auth.AuthSettings here
  tenantId, languageCode, useDeviceLanguage, persistence, _appCheckInstances) {
    this.schedulers = schedulers;
    const useEmulator = _useEmulator;
    const app = _firebaseAppFactory(options, zone, name);
    if (auth) {
      _authFactory(app, zone, useAuthEmulator, tenantId, languageCode, useDeviceLanguage, authSettings, persistence);
    }
    this.database = _cacheInstance(`${app.name}.database.${databaseURL}`, 'AngularFireDatabase', app.name, () => {
      const database = zone.runOutsideAngular(() => app.database(databaseURL || undefined));
      if (useEmulator) {
        database.useEmulator(...useEmulator);
      }
      return database;
    }, [useEmulator]);
  }
  list(pathOrRef, queryFn) {
    const ref = inject(NgZone).runOutsideAngular(() => getRef(this.database, pathOrRef));
    let query = ref;
    if (queryFn) {
      query = queryFn(ref);
    }
    return createListReference(query, this, this.injector);
  }
  object(pathOrRef) {
    const ref = inject(NgZone).runOutsideAngular(() => getRef(this.database, pathOrRef));
    return createObjectReference(ref, this, this.injector);
  }
  createPushId() {
    const ref = inject(NgZone).runOutsideAngular(() => this.database.ref());
    return ref.push().key;
  }
  static ɵfac = function AngularFireDatabase_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AngularFireDatabase)(i0.ɵɵinject(FIREBASE_OPTIONS), i0.ɵɵinject(FIREBASE_APP_NAME, 8), i0.ɵɵinject(URL, 8), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ɵAngularFireSchedulers), i0.ɵɵinject(USE_EMULATOR, 8), i0.ɵɵinject(i2.AngularFireAuth, 8), i0.ɵɵinject(USE_EMULATOR$1, 8), i0.ɵɵinject(SETTINGS, 8), i0.ɵɵinject(TENANT_ID, 8), i0.ɵɵinject(LANGUAGE_CODE, 8), i0.ɵɵinject(USE_DEVICE_LANGUAGE, 8), i0.ɵɵinject(PERSISTENCE, 8), i0.ɵɵinject(i3.AppCheckInstances, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AngularFireDatabase,
    factory: AngularFireDatabase.ɵfac,
    providedIn: 'any'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFireDatabase, [{
    type: Injectable,
    args: [{
      providedIn: 'any'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [FIREBASE_OPTIONS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [FIREBASE_APP_NAME]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [URL]
    }]
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.NgZone
  }, {
    type: i1.ɵAngularFireSchedulers
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [USE_EMULATOR]
    }]
  }, {
    type: i2.AngularFireAuth,
    decorators: [{
      type: Optional
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [USE_EMULATOR$1]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [SETTINGS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [TENANT_ID]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LANGUAGE_CODE]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [USE_DEVICE_LANGUAGE]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [PERSISTENCE]
    }]
  }, {
    type: i3.AppCheckInstances,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class AngularFireDatabaseModule {
  constructor() {
    firebase.registerVersion('angularfire', VERSION.full, 'rtdb-compat');
  }
  static ɵfac = function AngularFireDatabaseModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AngularFireDatabaseModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AngularFireDatabaseModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [AngularFireDatabase]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFireDatabaseModule, [{
    type: NgModule,
    args: [{
      providers: [AngularFireDatabase]
    }]
  }], () => [], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AngularFireDatabase, AngularFireDatabaseModule, URL, USE_EMULATOR, auditTrail, createListReference, fromRef, listChanges, snapshotChanges, stateChanges };
