import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ATS_LAYOUT_TYPE,
  ATS_LAYOUT_TYPE_DISPLAY,
  AtsLayoutSelectionModel
} from "../../../../resume/common/layout-selection.model";
import {NgClass} from "@angular/common";

@Component({
  selector: 'tal-ats-layout-selection',
  standalone: true,
  templateUrl: './ats-layout-selection.component.html',
  imports: [
    NgClass
  ],
  styleUrls: ['./ats-layout-selection.component.scss']
})
export class AtsLayoutSelectionComponent implements OnInit {
  @Input() atsLayoutSelectionModels: AtsLayoutSelectionModel[];
  @Input() currentLayoutType = ATS_LAYOUT_TYPE.MODERN;
  @Output() onChangeATSLayout = new EventEmitter();
  ATS_LAYOUT_TYPE_DISPLAY = ATS_LAYOUT_TYPE_DISPLAY;

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(atsLayoutSelectionModel: AtsLayoutSelectionModel): void {
    this.onChangeATSLayout.emit(atsLayoutSelectionModel);
  }
}
